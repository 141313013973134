<template>
<div class="row mb-2" style="padding: 10px; border: 1px solid #333; background-color: white;margin-top:20px;width:100%;margin-left:2px" v-if="show">
  <v-row class="mt-5" v-if="loading">
    <v-col>
      <v-skeleton-loader
      class="mx-auto"
      min-width="200"
      type="table"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
  <v-simple-table width="100%" ref="tableLembarMonitoring">
    <tr>
      <td colspan="3" style="border-bottom: 1px solid #333; text-align: center;">
      <h3><b>MONITORING AKTIVITAS PRIBADI PADA BULAN {{items.bulan}}</b></h3></td>
    </tr>
    <tR><td>&nbsp;</td></tR>
    <tr><td width="100px">NIP</td><td width="1px">:</td><td> &emsp;{{items.peg_nip}}</td></tr>
    <tr><td width="100px">NAMA</td><td width="1px">:</td><td> &emsp;{{items.peg_nama}}</td></tr>
    <tr><td width="100px">FOTO</td><td width="1px">:</td><td> &emsp;<v-avatar class="square" tile size="120"> <v-img :src="items.avatarPhoto"></v-img></v-avatar></td></tr>
    <tr><td width="100px">SKPD</td><td width="1px">:</td><td> &emsp;{{jabatan.kolok}}</td></tr>
    <tr><td width="100px">JABATAN</td><td width="1px">:</td><td> &emsp;{{jabatan.jabatan}}</td></tr>
    <tr><td width="100px">LEVEL </td><td width="1px">:</td><td> &emsp;{{ capaian.jenis == 1 ? 'Staf' : (capaian.jenis == 2 ? 'Struktural Atasan' : (capaian.jenis == 3 ? 'Struktural Kepala' : '-')) }}</td></tr>	
    <tr>
      <td colspan="3" style="border-bottom: 1px solid #333">&nbsp;</td>
    </tr>
    <tr><td colspan="3">
    <br>
		<h4>MONITORING AKTIVITAS UTAMA BULAN {{items.bulan}}</h4>
		<v-simple-table class="table table-bordered" width="100%">
			<thead>
      <tr>
        <th>AKTIVITAS</th>
        <th>
            FREKUENSI LAPOR
        </th>
        <th>
            TOTAL VOLUME
        </th>
        <th>
          BOBOT
        </th>
        <th>
          SATUAN MENIT
        </th>
        <th>
          TOTAL MENIT
        </th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="(a, i) in aktivitas" v-bind:key="i">
          <td>{{a.nama_aktifitas}}
          </td>
          <td>{{a.frekuensi}}</td>
          <td>{{a.total_volume}} {{ a.angka ? 'x' : '' }} {{a.satuan_output}}</td>
          <td>{{a.tingkat_kesulitan == null ? '-' : a.tingkat_kesulitan}}</td>
          <td>{{a.waktu}} Menit</td>
          <td>{{a.total_menit}} Menit</td>
        </tr>
      </tbody>
    </v-simple-table>
    </td></tr>
		<tr><td colspan="3">
      <br>
			<h4>MONITORING AKTIVITAS TAMBAHAN BULAN {{items.bulan}}</h4>
			<v-simple-table class="table table-bordered" width="100%">
				<thead>
          <tr>
            <th>AKTIVITAS</th>
            <th>
                FREKUENSI LAPOR
            </th>
            <th>
                TOTAL VOLUME
            </th>
            <th>
              BOBOT
            </th>
            <th>
              SATUAN MENIT
            </th>
            <th>
              TOTAL MENIT
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(a, i) in aktivitas_tambahan" v-bind:key="i">
            <td>{{a.nama_aktifitas}}
            </td>
            <td>{{a.frekuensi}}</td>
            <td>{{a.total_volume}} {{ a.angka ? 'x' : '' }} {{a.satuan_output}}</td>
            <td>{{a.tingkat_kesulitan == null ? '-' : a.tingkat_kesulitan}}</td>
            <td>{{a.waktu}} Menit</td>
            <td>{{a.total_menit}} Menit</td>
          </tr>
        </tbody>
      </v-simple-table>
      <br><br>
		</td></tr>
		<tr><td colspan="3">
			<h4>LIST AKTIVITAS UTAMA DISETUJUI BULAN {{items.bulan}}</h4>
			<v-simple-table class="table table-bordered" width="100%">
        <thead>
            <tr>
              <th>WAKTU</th>
              <th>
                AKTIVITAS
              </th>
              <th>
                WAKTU - VOLUME
              </th>
              
              <th>
                NILAI
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="setuju.length > 0">
              <template v-for="(s, i) in setuju">
                <tr v-bind:key="i">
                  <td colspan="4">{{s.tanggal}}</td>
                </tr>

                <tr v-for="(is, ii) in s.isi_aktifitas" v-bind:key="ii">
                  <td>{{is.jam_mulai | paddingNumberClock}}:{{is.menit_mulai | paddingNumberClock}}&nbsp;-&nbsp;{{is.jam_selesai | paddingNumberClock}}:{{is.menit_selesai | paddingNumberClock}}</td>
                  <td><b>{{is.aktivitas.nama_aktifitas}}</b><br>{{is.keterangan}}
                    <br>
                    <v-btn small color="yellow" @click="edit(is)" v-if="uname == '198508272003122001'" class="btn btn-info btn-xs"><span class="fa fa-edit"></span> Edit</v-btn>
                    <v-btn small color="red" @click="tolak(is)"  v-if="uname == '198508272003122001'" class="btn btn-danger btn-xs"><span class="fa fa-trash-o"></span> Hapus</v-btn>
                    <v-btn small color="primary" @click="validasi(is)" class="btn btn-success btn-xs"><span class="fa fa-check"></span> Validasi</v-btn>
                  </td>
                  <td>@{{is.aktivitas.waktu}} X <span v-if="is.aktivitas.tingkat_kesulitan != 1">{{is.aktivitas.tingkat_kesulitan}} X</span> {{is.volume}} {{ is.angka ? 'x' : '' }} {{is.aktivitas.satuan_output}}</td>
                  <td>{{is.total}}</td>
                </tr>
                <tr v-bind:key="i"><td colspan="3" style="text-align: right;font-weight: bold;">TOTAL PER HARI :  </td><td>{{s.total_hari == "0" ? s.total : s.total_hari}}</td></tr>
              </template>
              <template v-if="total > 0">
                <tr><td colspan="3" style="text-align: right;font-weight: bold;">TOTAL :  </td><td>{{total}}</td></tr>
              </template>
            </template>
            <template v-else>
              <tr>
                <td colspan="4">Tidak Ada Data Ditemukan</td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
		</td></tr>
    <tr><td colspan="3">
      <h4>LIST AKTIVITAS TAMBAHAN DISETUJUI BULAN {{items.bulan}}</h4>
      <v-simple-table class="table table-bordered" width="100%">
        <thead>
            <tr>
              <th>WAKTU</th>
              <th>
                  AKTIVITAS
              </th>
              <th>
                  WAKTU - VOLUME
              </th>
              
              <th>
                NILAI
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="setuju_tambahan.length > 0">
              <template v-for="(s, i) in setuju_tambahan">
                <tr v-bind:key="i">
                  <td colspan="4">{{s.tanggal}}</td>
                </tr>

                <tr v-for="(is, ii) in s.isi_aktifitas" v-bind:key="ii">
                  <td>{{is.jam_mulai | paddingNumberClock}}:{{is.menit_mulai | paddingNumberClock}}&nbsp;-&nbsp;{{is.jam_selesai | paddingNumberClock}}:{{is.menit_selesai | paddingNumberClock}}</td>
                  <td><b>{{is.aktivitas.nama_aktifitas}}</b><br>{{is.keterangan}}
                    <br>
                    <v-btn small color="yellow" @click="edit(is)" v-if="uname == '198508272003122001'" class="btn btn-info btn-xs"><span class="fa fa-edit"></span> Edit</v-btn>
                    <v-btn small color="red" @click="tolak(is)" v-if="uname == '198508272003122001'" class="btn btn-danger btn-xs"><span class="fa fa-trash-o"></span> Hapus</v-btn>
                    <v-btn small color="primary" @click="validasi(is)" class="btn btn-success btn-xs"><span class="fa fa-check"></span> Validasi</v-btn>
                  </td>
                  <td>@{{is.aktivitas.waktu}} X <span v-if="is.aktivitas.tingkat_kesulitan != 1">{{is.aktivitas.tingkat_kesulitan}} X</span> {{is.volume}} {{ is.angka ? 'x' : '' }} {{is.aktivitas.satuan_output}}</td>
                  <td>{{is.total}}</td>
                </tr>
                <tr v-bind:key="i"><td colspan="3" style="text-align: right;font-weight: bold;">TOTAL PER HARI :  </td><td>{{s.total_hari}}</td></tr>
              </template>
              <template v-if="total > 0">
                <tr><td colspan="3" style="text-align: right;font-weight: bold;">TOTAL :  </td><td>{{total}}</td></tr>
              </template>
            </template>
            <template v-else>
              <tr>
                <td colspan="4">Tidak Ada Data Ditemukan</td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
    </td></tr>
  </v-simple-table>
</div>
<div v-else align="center">
  <v-row class="mt-5" v-if="loading">
    <v-col>
      <v-skeleton-loader
      class="mx-auto"
      min-width="200"
      type="table"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
  <v-row class="mt-5" v-else>
    <v-col>
      Belum ada data
    </v-col>
  </v-row>
</div>
</template>

<script>
import _ from 'lodash'
import MonitoringAktivitasService from '@/services/MonitoringAktivitasService'
// import store from '@/store'
import _g from '../../../global'
// import axios from 'axios'
// import {local} from '@/store/local'

export default {
  components:{
  },

  props:{
    "dataLembar": Object,
    "awal": String,
    "akhir": String,
    "forceUpdateTable": Boolean
  },

  data(){
    return{
      forceUpdateFlag: false,
      forceUpdate:false,
      loading:false,
      detailData:{},

      headers:[
        {value:'nama'},
        {value:'jabatan'},
        {value:'satuan_kerja'},
        {value:'jumlah_aktivitas'},
        {value:'utama_jumlah_aktivitas'},
        {value:'utama_total_menit'},
        {value:'tambahan_jumlah_aktivitas'},
        {value:'tambahan_total_menit'},
        {value:'jumlah_sudah_review'},
        {value:'jumlah_review_seharusnya'}
      ],
      items:[],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableDetail:false,
        enableCreate:false,
      },
      rowPerPage:0,
      page:1,
      totalKeseluruhan:0,
      selectSKPD:'',
      skpd:[],
      search: '',
      dialogLihatSKP: false,
      dataSKP: [],
      loadingLihatSKP: false,
      nilai_total_capaian: null,
      selected: {},
      tahun: null,
      bulanList:[
        { b:'Januari',k:1},
        { b:'Febuari',k:2},
        { b:'Maret',k:3},
        { b:'April', k:4},
        { b:'Mei', k:5},
        { b:'Juni', k:6},
        { b:'Juli', k:7},
        { b:'Agustus', k:8},
        { b:'September', k:9},
        { b:'Oktober', k:10},
        { b:'November', k:11},
        { b:'Desember', k:12},
      ],
      selectBulan:null,
      fromDateMenu: false,
      fromDateVal: null,
      endDateMenu: false,
      endDateVal: null,
      namaPegawai: '',
      jabatan : [],
      capaian : [],
      aktivitas : [],
      aktivitas_tambahan : [],
      setuju : [],
      setuju_tambahan : [],
      uname : [],
      total : [],
      total_tambahan : [],
      show: true
    }
  },

  computed: {
  },

  watch: {
    // responseData(params) {
    //   console.log('responseData',params)
    //   this.items = params
    //   // this.updateTable(params)
    // },
    // forceUpdateTable(val){
    //   console.log('force update flag',  val)
    //   if (val && this.forceUpdateFlag){
    //     this.$emit('onTableChange')
    //     this.forceUpdateFlag = false
    //   } else if( val == false) {
    //       this.forceUpdateFlag = true
    //   }
    // },
    dataLembar(value){
      if(value){
        this.items = value
      }
    }
  },

  mounted(){
    this.$watch( vm => [vm.responseData],val =>{
      this.items = val
    })
  },

  created(){
    var params = null
    this.updateTable(params)
  },

  methods:{
    updateTable(params){
      this.dataLembar = params ? params : this.dataLembar
      if(params != null)
      this.loading = true
      this.items = []
      this.show = false
      MonitoringAktivitasService.getDataAktivitasPegawai({
        nip:this.dataLembar.peg_nip,
        bulan:this.dataLembar.bulan,
        awal:this.dataLembar.awal,
        akhir:this.dataLembar.akhir,
        filter:this.dataLembar.filter})
      .then((response)=>{
        var data = response.data.data
        data.pegawai.avatarPhoto = process.env.VUE_APP_IMG_URL + data.pegawai.peg_foto
        console.log(data)
        this.items = data.pegawai;
        this.jabatan = data.jabatan;
        this.capaian = data.penilaian;
        this.aktivitas = data.aktivitas;
        this.aktivitas_tambahan = data.aktivitas_tambahan;
        this.setuju = data.setujui;
        this.setuju_tambahan = data.setujui_tambahan;
        this.uname = data.uname;
        for (var i = 0; i < data.setujui.length; i++) {
          this.total = data.setujui[i].total;
        }
        for (var a = 0; a < data.setujui_tambahan.length; a++) {
          this.total_tambahan = data.setujui_tambahan[a].total;
        }
        this.show = true
        this.loading = false
      }).finally(()=>{
        this.loading = false
      })
    },
    reload(params){
      MonitoringAktivitasService.getDataAktivitasPegawai({
        nip: params.peg_nip,
        bulan: params.bulan,
        awal: params.awal,
        akhir: params.akhir,
        filter: params.filter})
      .then((response)=>{
        var data = response.data.data
        data.pegawai.avatarPhoto = process.env.VUE_APP_IMG_URL + data.pegawai.peg_foto
        console.log(data)
        this.items = data.pegawai;
        this.jabatan = data.jabatan;
        this.capaian = data.penilaian;
        this.aktivitas = data.aktivitas;
        this.aktivitas_tambahan = data.aktivitas_tambahan;
        this.setuju = data.setujui;
        this.setuju_tambahan = data.setujui_tambahan;
        this.uname = data.uname;
        for (var i = 0; i < data.setujui.length; i++) {
          this.total = data.setujui[i].total;
        }
        for (var a = 0; a < data.setujui_tambahan.length; a++) {
          this.total_tambahan = data.setujui_tambahan[a].total;
        }
        this.show = true
        this.loading = false
      }).finally(()=>{
        this.loading = false
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.pegawai-table{
  tr,th,td {
    border: 1px solid #ddd;
  }
  thead tr th{
    color:white;
  }
}
</style>
